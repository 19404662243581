<template>
  <div class="">
    <article class="card mb-4">
      <header class="card-header text-center font-proxima">
        <div class="card-meta fs--1">
          <strong>{{daysFrom('2020-05-05')}}</strong>
          в категории 
          <router-link to="/blog?category=management" 
            class="badge badge-secondary fs--0 text-uppercase">
            Управление
          </router-link>
        </div>
        <h1 class="card-title fs-2 mt-1 font-proxima">
          {{blogPost ? blogPost.title : ''}}
        </h1>
      </header>
      <img class="img-fluid" :src="blogPost ? blogPost.imageUrl.i : ''" alt="" />
      <div v-html="blogPost ? blogPost.body: ''"
        class="card-body mobile-padding">
      </div>
      <div class="card-footer bg-light pt-0">
        <div class="row no-gutters font-weight-semi-bold text-center py-2 fs--1">
          <div class="col-auto mr-3">
            <img src="@/assets/img/illustrations/like-inactive.png" width="20" alt="">
            <span class="ml-2">
              {{blogPost ? blogPost.likes : 0}}
            </span>
          </div>
          <div class="col-auto mr-3">
            <img src="@/assets/img/illustrations/comment-inactive.png" width="20" alt="">
            <span class="ml-2">0</span>
          </div>
          <div class="col-auto d-flex align-items-center">
            <a class="rounded text-700 d-flex align-items-center" href="#!">
              <img src="@/assets/img/illustrations/share-inactive.png" width="20" alt="">
              <span class="ml-1">Поделиться</span>
            </a>
          </div>
        </div>
        <form class="d-flex align-items-center border-top border-200 pt-3">
          <div v-if="false" 
            class="avatar avatar-xl">
            <img class="rounded-soft"  alt="">
          </div>
          <input class="form-control rounded-capsule ml-2 fs--1" 
            disabled
            type="text"      
            placeholder="Оставьте комментарий...">
            <div class="btn btn-secondary btn-sm ml-3 disabled">Отправить</div>
        </form>
      </div>
    </article>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  metaInfo() {
    return {
      title: this.blogPost.title
    }
  },
  async created() {
    await this.loadBlogPosts();
  },

  computed: {
    blogPost() {
      return this.$store.getters.getCurrentBlogPost;
    }
  },

  methods: {
    daysFrom(date) {
      return moment().to(date);
    },

    async loadBlogPosts() {
      const postId = this.$route.query.id;
      await this.$store.dispatch('fetchBlogPostsList');
      this.$store.commit('setCurrentBlogPost', postId)
    }
  }

}
</script>

<style lang="scss" scoped>
@media (max-width: 400px) {
  .mobile-not-display {
    display: none !important; 
  }

  .mobile-padding {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}
</style>